import { authTypes, integrationTypes } from "redux/Ldb/types";

const initialState = {
    fetchingQuickbooksOauthUrl: false,
    fetchedQuickbooksOauthUrlSuccess: null,
    fetchedQuickbooksOauthUrlFailure: null,
    fetchingQuickbooksExchangeCode: false,
    fetchedQuickbooksExchangeCodeSuccess: null,
    fetchedQuickbooksExchangeCodeFailure: null,
    fetchingQuickbooksApAccounts: false,
    fetchedQuickbooksApAccountsSuccess: null,
    fetchedQuickbooksApAccountsFailure: null,
    fetchingQuickbooksExpenseAccounts: false,
    fetchedQuickbooksExpenseAccountsSuccess: null,
    fetchedQuickbooksExpenseAccountsFailure: null,
    setQuickbooksAccount: false,
    setQuickbooksAccountSuccess: null,
    setQuickbooksAccountFailure: null,
    fetchingShopifyOauthUrl: false,
    fetchedShopifyOauthUrlSuccess: null,
    fetchedShopifyOauthUrlFailure: null,
    verifyShopifyAccount: false,
    verifyShopifyAccountSuccess: null,
    verifyShopifyAccountFailure: null,
    fetchSocialAccount: false,
    fetchSocialAccountSuccess: null,
    fetchSocialAccountFailure: null,
    fetchShopifyData: false,
    fetchShopifyDataSuccess: null,
    fetchShopifyDataFailure: null,
    updateSocialAccount: false,
    updateSocialAccountSuccess: null,
    updateSocialAccountFailure: null,
    fetchingQuickbooksIncomeAccounts: false,
    fetchedQuickbooksIncomeAccountsSuccess: null,
    fetchedQuickbooksIncomeAccountsFailure: null,
    fetchingQuickbooksAssetAccounts: false,
    fetchedQuickbooksAssetAccountsSuccess: null,
    fetchedQuickbooksAssetAccountsFailure: null,
    fetchingAmazonOauthUrl: false,
    fetchedAmazonOauthUrlSuccess: null,
    fetchedAmazonOauthUrlFailure: null,
    verifyAmazonAccount: false,
    verifyAmazonAccountSuccess: null,
    verifyAmazonAccountFailure: null,
};

export const integration = (state = initialState, action: { type: string; payload: any }) => {
    switch (action.type) {
        case integrationTypes.FETCH_QUICKBOOKS_INCOME_ACCOUNTS_REQUEST:
            return {
                ...state,
                fetchingQuickbooksIncomeAccounts: true,
                fetchedQuickbooksIncomeAccountsSuccess: null,
                fetchedQuickbooksIncomeAccountsFailure: null,
            };
        case integrationTypes.FETCH_QUICKBOOKS_INCOME_ACCOUNTS_SUCCESS:
            return {
                ...state,
                fetchingQuickbooksIncomeAccounts: false,
                fetchedQuickbooksIncomeAccountsSuccess: action.payload,
                fetchedQuickbooksIncomeAccountsFailure: null,
            };
        case integrationTypes.FETCH_QUICKBOOKS_INCOME_ACCOUNTS_FAILURE:
            return {
                ...state,
                fetchingQuickbooksIncomeAccounts: false,
                fetchedQuickbooksIncomeAccountsSuccess: null,
                fetchedQuickbooksIncomeAccountsFailure: action.payload,
            };

        case integrationTypes.FETCH_QUICKBOOKS_ASSET_ACCOUNTS_REQUEST:
            return {
                ...state,
                fetchingQuickbooksAssetAccounts: true,
                fetchedQuickbooksAssetAccountsSuccess: null,
                fetchedQuickbooksAssetAccountsFailure: null,
            };
        case integrationTypes.FETCH_QUICKBOOKS_ASSET_ACCOUNTS_SUCCESS:
            return {
                ...state,
                fetchingQuickbooksAssetAccounts: false,
                fetchedQuickbooksAssetAccountsSuccess: action.payload,
                fetchedQuickbooksAssetAccountsFailure: null,
            };
        case integrationTypes.FETCH_QUICKBOOKS_ASSET_ACCOUNTS_FAILURE:
            return {
                ...state,
                fetchingQuickbooksAssetAccounts: false,
                fetchedQuickbooksAssetAccountsSuccess: null,
                fetchedQuickbooksAssetAccountsFailure: action.payload,
            };

        case integrationTypes.GET_QUICKBOOKS_OAUTH_URL_REQUEST:
            return {
                ...state,
                fetchingQuickbooksOauthUrl: true,
                fetchedQuickbooksOauthUrlSuccess: null,
                fetchedQuickbooksOauthUrlFailure: null,
            };
        case integrationTypes.GET_QUICKBOOKS_OAUTH_URL_SUCCESS:
            return {
                ...state,
                fetchingQuickbooksOauthUrl: false,
                fetchedQuickbooksOauthUrlSuccess: action.payload,
                fetchedQuickbooksOauthUrlFailure: null,
            };
        case integrationTypes.GET_QUICKBOOKS_OAUTH_URL_FAILURE:
            return {
                ...state,
                fetchingQuickbooksOauthUrl: false,
                fetchedQuickbooksOauthUrlSuccess: null,
                fetchedQuickbooksOauthUrlFailure: action.payload,
            };

        case integrationTypes.GET_QUICKBOOKS_EXCHANGE_CODE_REQUEST:
            return {
                ...state,
                fetchingQuickbooksExchangeCode: true,
                fetchedQuickbooksExchangeCodeSuccess: null,
                fetchedQuickbooksExchangeCodeFailure: null,
            };
        case integrationTypes.GET_QUICKBOOKS_EXCHANGE_CODE_SUCCESS:
            return {
                ...state,
                fetchingQuickbooksExchangeCode: false,
                fetchedQuickbooksExchangeCodeSuccess: action.payload,
                fetchedQuickbooksExchangeCodeFailure: null,
            };
        case integrationTypes.GET_QUICKBOOKS_EXCHANGE_CODE_FAILURE:
            return {
                ...state,
                fetchingQuickbooksExchangeCode: false,
                fetchedQuickbooksExchangeCodeSuccess: null,
                fetchedQuickbooksExchangeCodeFailure: action.payload,
            };
        case integrationTypes.FETCH_QUICKBOOKS_AP_ACCOUNTS_REQUEST:
            return {
                ...state,
                fetchingQuickbooksApAccounts: true,
                fetchedQuickbooksApAccountsSuccess: null,
                fetchedQuickbooksApAccountsFailure: null,
            };
        case integrationTypes.FETCH_QUICKBOOKS_AP_ACCOUNTS_SUCCESS:
            return {
                ...state,
                fetchingQuickbooksApAccounts: false,
                fetchedQuickbooksApAccountsSuccess: action.payload,
                fetchedQuickbooksApAccountsFailure: null,
            };
        case integrationTypes.FETCH_QUICKBOOKS_AP_ACCOUNTS_FAILURE:
            return {
                ...state,
                fetchingQuickbooksApAccounts: false,
                fetchedQuickbooksApAccountsSuccess: null,
                fetchedQuickbooksApAccountsFailure: action.payload,
            };
        case integrationTypes.FETCH_QUICKBOOKS_EXPENSE_ACCOUNTS_REQUEST:
            return {
                ...state,
                fetchingQuickbooksExpenseAccounts: true,
                fetchedQuickbooksExpenseAccountsSuccess: null,
                fetchedQuickbooksExpenseAccountsFailure: null,
            };
        case integrationTypes.FETCH_QUICKBOOKS_EXPENSE_ACCOUNTS_SUCCESS:
            return {
                ...state,
                fetchingQuickbooksExpenseAccounts: false,
                fetchedQuickbooksExpenseAccountsSuccess: action.payload,
                fetchedQuickbooksExpenseAccountsFailure: null,
            };
        case integrationTypes.FETCH_QUICKBOOKS_EXPENSE_ACCOUNTS_FAILURE:
            return {
                ...state,
                fetchingQuickbooksExpenseAccounts: false,
                fetchedQuickbooksExpenseAccountsSuccess: null,
                fetchedQuickbooksExpenseAccountsFailure: action.payload,
            };

        case integrationTypes.SET_UP_QUICKBOOKS_ACCOUNT_REQUEST:
            return {
                ...state,
                setQuickbooksAccount: true,
                setQuickbooksAccountSuccess: null,
                setQuickbooksAccountFailure: null,
            };
        case integrationTypes.SET_UP_QUICKBOOKS_ACCOUNT_SUCCESS:
            return {
                ...state,
                setQuickbooksAccount: false,
                setQuickbooksAccountSuccess: action.payload,
                setQuickbooksAccountFailure: null,
            };
        case integrationTypes.SET_UP_QUICKBOOKS_ACCOUNT_FAILURE:
            return {
                ...state,
                setQuickbooksAccount: false,
                setQuickbooksAccountSuccess: null,
                setQuickbooksAccountFailure: action.payload,
            };

        case integrationTypes.GET_SHOPIFY_OAUTH_URL_REQUEST:
            return {
                ...state,
                fetchingShopifyOauthUrl: true,
                fetchedShopifyOauthUrlSuccess: null,
                fetchedShopifyOauthUrlFailure: null,
            };
        case integrationTypes.GET_SHOPIFY_OAUTH_URL_SUCCESS:
            return {
                ...state,
                fetchingShopifyOauthUrl: false,
                fetchedShopifyOauthUrlSuccess: action.payload,
                fetchedShopifyOauthUrlFailure: null,
            };
        case integrationTypes.GET_SHOPIFY_OAUTH_URL_FAILURE:
            return {
                ...state,
                fetchingShopifyOauthUrl: false,
                fetchedShopifyOauthUrlSuccess: null,
                fetchedShopifyOauthUrlFailure: action.payload,
            };
        case integrationTypes.VERIFY_SHOPIFY_ACCOUNT_REQUEST:
            return {
                ...state,
                verifyShopifyAccount: true,
                verifyShopifyAccountSuccess: null,
                verifyShopifyAccountFailure: null,
            };
        case integrationTypes.VERIFY_SHOPIFY_ACCOUNT_SUCCESS:
            return {
                ...state,
                verifyShopifyAccount: false,
                verifyShopifyAccountSuccess: action.payload,
                verifyShopifyAccountFailure: null,
            };
        case integrationTypes.VERIFY_SHOPIFY_ACCOUNT_FAILURE:
            return {
                ...state,
                verifyShopifyAccount: false,
                verifyShopifyAccountSuccess: null,
                verifyShopifyAccountFailure: action.payload,
            };
        case integrationTypes.FETCH_SOCIAL_ACCOUNT_REQUEST:
            return {
                ...state,
                fetchSocialAccount: true,
                fetchSocialAccountSuccess: null,
                fetchSocialAccountFailure: null,
            };
        case integrationTypes.FETCH_SOCIAL_ACCOUNT_SUCCESS:
            return {
                ...state,
                fetchSocialAccount: false,
                fetchSocialAccountSuccess: action.payload,
                fetchSocialAccountFailure: null,
            };
        case integrationTypes.FETCH_SOCIAL_ACCOUNT_FAILURE:
            return {
                ...state,
                fetchSocialAccount: false,
                fetchSocialAccountSuccess: null,
                fetchSocialAccountFailure: action.payload,
            };

        case integrationTypes.UPDATE_SOCIAL_ACCOUNT_REQUEST:
            return {
                ...state,
                updateSocialAccount: true,
                updateSocialAccountSuccess: null,
                updateSocialAccountFailure: null,
            };
        case integrationTypes.UPDATE_SOCIAL_ACCOUNT_SUCCESS:
            return {
                ...state,
                updateSocialAccount: false,
                updateSocialAccountSuccess: action.payload,
                updateSocialAccountFailure: null,
            };
        case integrationTypes.UPDATE_SOCIAL_ACCOUNT_FAILURE:
            return {
                ...state,
                updateSocialAccount: false,
                updateSocialAccountSuccess: null,
                updateSocialAccountFailure: action.payload,
            };
        case integrationTypes.FETCH_SHOPIFY_DATA_REQUEST:
            return {
                ...state,
                fetchShopifyData: true,
                fetchShopifyDataSuccess: null,
                fetchShopifyDataFailure: null,
            };
        case integrationTypes.FETCH_SHOPIFY_DATA_SUCCESS:
            return {
                ...state,
                fetchShopifyData: false,
                fetchShopifyDataSuccess: action.payload,
                fetchShopifyDataFailure: null,
            };
        case integrationTypes.FETCH_SHOPIFY_DATA_FAILURE:
            return {
                ...state,
                fetchShopifyData: false,
                fetchShopifyDataSuccess: null,
                fetchShopifyDataFailure: action.payload,
            };

        case integrationTypes.GET_AMAZON_OAUTH_URL_REQUEST:
            return {
                ...state,
                fetchingAmazonOauthUrl: true,
                fetchedAmazonOauthUrlSuccess: null,
                fetchedAmazonOauthUrlFailure: null,
            };
        case integrationTypes.GET_AMAZON_OAUTH_URL_SUCCESS:
            return {
                ...state,
                fetchingAmazonOauthUrl: false,
                fetchedAmazonOauthUrlSuccess: action.payload,
                fetchedAmazonOauthUrlFailure: null,
            };
        case integrationTypes.GET_AMAZON_OAUTH_URL_FAILURE:
            return {
                ...state,
                fetchingAmazonOauthUrl: false,
                fetchedAmazonOauthUrlSuccess: null,
                fetchedAmazonOauthUrlFailure: action.payload,
            };
        case integrationTypes.VERIFY_AMAZON_ACCOUNT_REQUEST:
            return {
                ...state,
                verifyAmazonAccount: true,
                verifyAmazonAccountSuccess: null,
                verifyAmazonAccountFailure: null,
            };
        case integrationTypes.VERIFY_AMAZON_ACCOUNT_SUCCESS:
            return {
                ...state,
                verifyAmazonAccount: false,
                verifyAmazonAccountSuccess: action.payload,
                verifyAmazonAccountFailure: null,
            };
        case integrationTypes.VERIFY_AMAZON_ACCOUNT_FAILURE:
            return {
                ...state,
                verifyAmazonAccount: false,
                verifyAmazonAccountSuccess: null,
                verifyAmazonAccountFailure: action.payload,
            };
        default:
            return state;
    }
};
