import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import { displayProductChannelIcon } from "helpers/displayProductChannelIcon";
import useGetSingleMappedSku from "hooks/inventoryHooks/useGetSingleMappedSku";
import { formatMoney } from "helpers";
import { truncate } from "lodash";
import PageFrame from "components/layout/PageFrame";
import VendorAvatar from "components/common/VendorAvatar";
import ModalContainer from "components/common/ModalContainer";
import Checkbox from "components/common/InputField/Checkbox";
import Loader from "components/common/Loader";
import useGetProductList from "hooks/inventoryHooks/useGetProductList";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import toast from "react-hot-toast";
import CustomToast from "components/common/CustomToast";
import { Tooltip } from "react-tooltip";

interface Body {
    mappedSkus: {
        _id: string;
        productName: string;
        sku: string;
        price: {
            amount: number;
        };
        channels: string[];
    }[];
    sku?: string;
}

const EditSku = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { productId } = useParams();
    const [selectedProducts, setSelectedProducts] = useState<{ [key: string]: any }[]>([]);
    const [mappedProducts, setMappedProducts] = useState<{ [key: string]: any }[]>([]);
    const [skuNumber, setSkuNumber] = useState<string>("");
    const [disabledChannels, setDisabledChannels] = useState([]);
    const [editMapped, setEditMapped] = useState<boolean>(false);
    const [addProduct, setAddProduct] = useState<boolean>(false);
    const [editSuccess, setShowEditSuccess] = useState<boolean>(false);
    const { data: singleMappedData, isFetching: isFetchingSingleMapped } = useGetSingleMappedSku();
    const { data, isFetching, total, handleFilterChange, setTracking, pagination, setRefetch } =
        useGetProductList();
    const { updatingSkuMapping, updatedSkuMappingSuccess } = useAppSelector(
        (state) => state.inventory
    );
    useEffect(() => {
        handleFilterChange(`Finished Goods`);
        setTracking(false);
    }, []);

    const unitPriceSum = Array.isArray(mappedProducts)
        ? mappedProducts.reduce((acc, curr) => acc + (curr.unitPrice || 0), 0)
        : 0;

    const stockLevelSum = Array.isArray(mappedProducts)
        ? mappedProducts.reduce((acc, curr) => acc + (curr.stockLevel || 0), 0)
        : 0;
    useEffect(() => {
        setSkuNumber(singleMappedData?.sku);
        setMappedProducts(singleMappedData?.mappedSkus);
    }, [singleMappedData]);

    useEffect(() => {
        if (mappedProducts?.length > 0) {
            const channels = [...new Set(mappedProducts.map((product) => product.channel))];
            setDisabledChannels(channels);
        }
    }, [mappedProducts]);
    const handleSelectItem = (item) => {
        setSelectedProducts((prev) => {
            const isAlreadySelected = prev.some(
                (selectedItem) => selectedItem?.channel === item?.channel
            );

            if (isAlreadySelected) {
                return prev.filter((selectedItem) => selectedItem?.channel !== item.channel);
            } else {
                return [...prev, item];
            }
        });
        setDisabledChannels((prev) => {
            const isAlreadyDisabled = prev.includes(item?.channel);

            if (isAlreadyDisabled) {
                return prev.filter((channel) => channel !== item?.channel);
            } else {
                return [...prev, item.channel];
            }
        });
    };

    const handleDeleteProduct = (sku) => {
        const updatedMappedSkus = mappedProducts?.filter((product) => product.sku !== sku);
        setMappedProducts(updatedMappedSkus);
    };

    const tableHeader = [
        { title: "Product name", widthClass: "w-[40%]" },
        { title: "Current stock level", widthClass: "w-[20%]" },
        { title: "Unit price", widthClass: "w-[20%]" },
        { title: "Channel", widthClass: "w-[20%]" },
        { title: "", widthClass: "w-[10%]" },
    ];

    const tableBody = mappedProducts?.map((product, idx) => {
        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`w-full py-2 border-slate-100 text-sm font-rocGroteskMedium text-slate-900 `}
                    >
                        <div className="font-rocGroteskMedium pl-3  items-center flex space-x-2">
                            {product?.productImageDetails?.productAvatar ||
                            product?.productImageDetails?.productImages?.[0] ? (
                                <VendorAvatar
                                    imageSrc={
                                        product?.productImageDetails?.productAvatar ||
                                        product?.productImageDetails?.productImages?.[0]
                                    }
                                    size={32}
                                    name={product?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}

                            <div className="text-sm font-rocGroteskMedium">
                                <p className="text-g-75 ">
                                    {truncate(product?.productName, { length: 30 })}
                                </p>
                                <p className="text-slate-500 ">
                                    SKU-{truncate(product?.sku, { length: 30 })}
                                </p>
                            </div>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-[0]",
            },
            {
                content: (
                    <div
                        className={`w-full py-2 border-slate-100 font-rocGroteskMedium text-sm text-g-75 `}
                    >
                        <p>{formatMoney().format(product?.stockLevel || 0)}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div
                        className={`w-full py-2 border-slate-100 font-rocGroteskMedium text-sm text-g-75 `}
                    >
                        <p>${formatMoney().format(product?.unitPrice)}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div className={`w-full py-2 border-slate-100 text-sm text-g-75 `}>
                        <div className="flex items-center space-x-2">
                            {displayProductChannelIcon(product?.channel)}
                            <span className="text-sm font-rocGroteskMedium capitalize">
                                {product?.channel === "quickbooks" ? "QB" : product?.channel}
                            </span>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div
                        className={`w-full cursor-pointer py-2 border-slate-100 text-sm text-g-75 `}
                    >
                        <div
                            className={`flex items-center space-x-2 ${
                                mappedProducts?.length === 1 && "opacity-50"
                            }`}
                            onClick={() => {
                                mappedProducts?.length > 1 && handleDeleteProduct(product?.sku);
                            }}
                        >
                            <i className="ri-delete-bin-line text-sm text-[#64748B]"></i>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
        ];
    });
    const onAddProducts = () => {
        const formattedProducts = selectedProducts.map((product) => ({
            productName: product.productName,
            sku: product.sku,
            productImageDetails: {
                productImages: product.productImageDetails?.productImages || [],
                productAvatar: product.productImageDetails?.productAvatar || "",
            },
            inventoryId: product._id,
            stockLevel: product.productStockDetails?.stockLevel,
            unitPrice: product.unitCost?.amount,
            currency: product.currency || "USD",
            channel: product.channel,
            channels: product.channels || [product.channel],
        }));

        setMappedProducts((prev) => {
            const newProducts = formattedProducts.filter(
                (product) => !prev.some((mappedProduct) => mappedProduct.sku === product.sku)
            );
            return [...prev, ...newProducts];
        });

        setAddProduct(false);
    };
    const conflictSku = mappedProducts?.filter((product) => skuNumber === product.sku);

    const onUpdateSkuMapping = () => {
        const body: Body = {
            mappedSkus: mappedProducts.map((sku) => ({
                _id: sku.inventoryId,
                productName: sku.productName,
                sku: sku.sku,
                price: {
                    amount: sku.unitPrice,
                },
                channels: sku.channels,
            })),
        };
        if (skuNumber !== singleMappedData?.sku) {
            body.sku = skuNumber;
        }
        if (skuNumber === "") {
            toast.custom((t) => (
                <CustomToast t={t} message={"Please input your sku number"} type={"error"} />
            ));
        } else if (conflictSku.length === 0) {
            dispatch(inventoryActions.updateSkuMapping(productId, body));
        }
    };
    useEffect(() => {
        if (Boolean(updatedSkuMappingSuccess)) {
            setEditMapped(false);
            setShowEditSuccess(true);
        }
    }, [updatedSkuMappingSuccess]);

    return (
        <PageFrame isLoading={isFetchingSingleMapped}>
            <div className="bg-white rounded-lg border border-n-20">
                <div className="flex items-center justify-between px-8 py-5 border-b border-n-20">
                    <div className="flex items-center space-x-1.5">
                        <i
                            onClick={() => navigate(-1)}
                            className="ri-arrow-left-line text-xl mt-[-2px] cursor-pointer max-sm:hidden"
                        ></i>
                        <span className="text-lg font-rocGroteskMedium">Edit SKU mapping</span>
                    </div>
                    <div className="flex items-center space-x-3">
                        <Button
                            btnText="Cancel"
                            btnClassname="!px-4 !py-2 !bg-tradeally-neutral-20 !w-fit"
                            disabled={updatingSkuMapping}
                            onClick={() => navigate("/dashboard/inventory?currTab=5")}
                        />
                        <Button
                            btnText="Save mapped SKU"
                            onClick={() => setEditMapped(true)}
                            btnClassname="!px-4 !py-2 !w-fit"
                        />
                    </div>
                </div>

                <div className="px-8 mt-8 flex justify-center">
                    <div className="w-[60%]">
                        <div className="mb-4">
                            <div className="flex items-center w-full justify-between mb-4">
                                <p className="text-sm font-rocGroteskMedium">
                                    Enter Master SKU <span className="text-r-50">*</span>
                                </p>
                            </div>
                            <div className="border-b-[1px] border-b-[#E2E8F0] pb-5">
                                <input
                                    type="text"
                                    placeholder="Enter SKU number"
                                    className="!text-2xl font-rocGroteskMedium  placeholder:text-[#CBD5E1] !border-none outline-none w-full placeholder:pl-1 caret-slate-700 !leading-6"
                                    name="skuNumber"
                                    value={skuNumber}
                                    onChange={(e) => setSkuNumber(e.target.value)}
                                    required
                                />
                            </div>
                        </div>

                        <div className="">
                            <div className="flex mb-3 justify-between  items-center w-full">
                                <div>
                                    <p className="text-base  font-rocGroteskMedium  text-gm-50">
                                        Products/SKUs
                                    </p>
                                </div>
                                <div>
                                    <Button
                                        btnText="Add Product(s)"
                                        onClick={() => {
                                            setRefetch(true);

                                            setAddProduct(true);
                                        }}
                                        btnClassname="!px-4 !py-2 !w-fit"
                                    />
                                </div>
                            </div>

                            <div className="pb-3">
                                <CustomTable
                                    tableBody={tableBody}
                                    tableHeader={tableHeader}
                                    isAllSelectable={false}
                                    isCellSelectable={false}
                                    headerContainerClass="!bg-[#F8FAFC] !font-rocGroteskMedium !text-[#334155] !rounded !border-slate-200"
                                    isScrollable={false}
                                    isCellBordered={false}
                                    bodyItemClass={"hover:!bg-transparent"}
                                />
                                <div className="flex w-[100%]  py-3  border border-t-0 border-slate-100 rounded-bl bg-[#F8FAFC] rounded-br">
                                    <div className="w-[40%] pl-3">
                                        <p className="text-sm font-rocGroteskMedium text-g-75">
                                            Totals: {mappedProducts?.length} product(s)
                                        </p>
                                    </div>
                                    <div className="w-[20%] pl-3">
                                        <p className="text-sm font-rocGroteskMedium text-g-75">
                                            {formatMoney().format(stockLevelSum)}
                                        </p>
                                    </div>
                                    <div className="w-[20%] pl-3">
                                        <p className="text-sm font-rocGroteskMedium text-g-75">
                                            ${formatMoney().format(unitPriceSum)}
                                        </p>
                                    </div>
                                    <div className="w-[20%] pl-3">
                                        <p className="text-sm font-rocGroteskMedium text-g-75">
                                            {formatMoney().format(disabledChannels.length)}{" "}
                                            channel(s)
                                        </p>
                                    </div>
                                    <div className="w-[10%] pl-3">
                                        <p className="text-sm font-rocGroteskMedium text-g-75"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalContainer
                open={addProduct}
                showCloseIcon={false}
                closeModal={() => {
                    setAddProduct(false);
                    setRefetch(false);
                }}
                modalClassName="px-10 py-10"
                tailwindClassName="w-[700px]"
            >
                <div className="bg-white  rounded-lg max-h-[90vh] overflow-y-scroll">
                    <div className=" px-6 py-4 flex gap-4 items-center justify-between border-b border-b-slate-200">
                        <p className="text-lg text-gm-50 font-rocGroteskMedium">
                            Add products/SKUs
                        </p>
                        <i
                            onClick={() => {
                                setAddProduct(false);
                            }}
                            className="ri-close-fill cursor-pointer text-gm-50 text-2xl "
                        ></i>
                    </div>
                    <div className="my-3 ">
                        <div
                            id="modalContent"
                            className="h-[48.8vh] overflow-y-auto space-y-3  pb-14"
                        >
                            {data.length === 0 && isFetching ? (
                                <Loader />
                            ) : (
                                <>
                                    {data?.map((item, idx) => {
                                        const isItemMapped = singleMappedData?.mappedSkus?.some(
                                            (mappedSku) => mappedSku.sku === item.sku
                                        );
                                        return (
                                            <div
                                                key={idx}
                                                className={`flex px-6   items-center justify-between border-b pb-2 border-slate-200`}
                                            >
                                                <div
                                                    className={` 
                                                     flex w-[80%] space-x-3 items-center`}
                                                >
                                                    <div>
                                                        <a id={`product-column-${idx}`}>
                                                            <Checkbox
                                                                name=""
                                                                isChecked={
                                                                    selectedProducts.some(
                                                                        (selectedItem) =>
                                                                            selectedItem.channel ===
                                                                                item.channel &&
                                                                            selectedItem.sku ===
                                                                                item.sku
                                                                    ) || isItemMapped
                                                                }
                                                                onChange={() =>
                                                                    handleSelectItem(item)
                                                                }
                                                                className={`checked:!bg-[#FFB902]
                                                                ${
                                                                    disabledChannels.includes(
                                                                        item.channel
                                                                    ) &&
                                                                    !selectedProducts.some(
                                                                        (selectedItem) =>
                                                                            selectedItem.channel ===
                                                                                item.channel &&
                                                                            selectedItem.sku ===
                                                                                item.sku
                                                                    ) &&
                                                                    "opacity-20"
                                                                }
                                                                 checked:!border-[#FFB902] !text-[#042821]`}
                                                                disabled={
                                                                    disabledChannels.includes(
                                                                        item.channel
                                                                    ) &&
                                                                    !selectedProducts.some(
                                                                        (selectedItem) =>
                                                                            selectedItem.channel ===
                                                                                item.channel &&
                                                                            selectedItem.sku ===
                                                                                item.sku
                                                                    )
                                                                }
                                                            />
                                                        </a>
                                                        {disabledChannels.includes(item.channel) &&
                                                            !selectedProducts.some(
                                                                (selectedItem) =>
                                                                    selectedItem.channel ===
                                                                        item.channel &&
                                                                    selectedItem.sku === item.sku
                                                            ) && (
                                                                <Tooltip
                                                                    anchorSelect={`#product-column-${idx}`}
                                                                    place={"top"}
                                                                    content={
                                                                        "You cannot map skus from the same channel"
                                                                    }
                                                                    style={{
                                                                        maxWidth: "250px",
                                                                        zIndex: 9999,
                                                                        background: "#142837",
                                                                        borderRadius: "6px",
                                                                        border: "1px solid #E2E8F0",
                                                                        color: "#FFFFFF",
                                                                        boxShadow:
                                                                            "0px 4px 16px 0px rgba(16, 24, 40, 0.03)",
                                                                    }}
                                                                />
                                                            )}
                                                    </div>

                                                    <div
                                                        className={`${
                                                            disabledChannels.includes(
                                                                item.channel
                                                            ) &&
                                                            !selectedProducts.some(
                                                                (selectedItem) =>
                                                                    selectedItem.channel ===
                                                                        item.channel &&
                                                                    selectedItem.sku === item.sku
                                                            ) &&
                                                            "opacity-20"
                                                        }`}
                                                    >
                                                        {item?.productImageDetails
                                                            ?.productImages?.[0] ? (
                                                            <VendorAvatar
                                                                imageSrc={
                                                                    item?.productImageDetails
                                                                        ?.productImages?.[0]
                                                                }
                                                                size={32}
                                                                name={item?.productName}
                                                                containerClassname="!rounded-md"
                                                            />
                                                        ) : (
                                                            <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                                                                <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <p className="text-sm font-rocGroteskMedium text-[#042821] ">
                                                            {item?.productName}
                                                        </p>
                                                        <p className="text-slate-500 text-sm font-rocGroteskMedium">
                                                            {item?.sku}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    className={` ${
                                                        disabledChannels.includes(item?.channel) &&
                                                        !selectedProducts.some(
                                                            (selectedItem) =>
                                                                selectedItem.channel ===
                                                                    item.channel &&
                                                                selectedItem.sku === item.sku
                                                        ) &&
                                                        "opacity-20"
                                                    } flex  w-[13%]  items-center space-x-1`}
                                                >
                                                    <span>
                                                        {displayProductChannelIcon(item?.channel)}{" "}
                                                    </span>
                                                    <span className="text-sm font-rocGroteskMedium text-[#042821]">
                                                        {item?.channel}
                                                    </span>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    {pagination?.currentPage === pagination?.noOfPages && (
                                        <div className="flex my-4 text-slate-500 justify-center text-sm font-rocGroteskMedium">
                                            <p className="text-center"> End of list</p>
                                        </div>
                                    )}
                                    {data.length > 0 && isFetching && (
                                        <div className="flex my-4 justify-center">
                                            <Loader color="gm-25" size={4} />
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>

                    <div className="flex items-center justify-between w-full bg-white border-t border-slate-200 px-6 py-4">
                        <div>
                            <p className="font-rocGroteskMedium text-sm">
                                {selectedProducts?.length} out of {total} products selected
                            </p>
                        </div>
                        <div className="flex items-center space-x-3">
                            <Button
                                btnText="Cancel"
                                btnClassname="!py-3 !bg-n-20"
                                disabled={false}
                                onClick={() => {
                                    setAddProduct(false);
                                    setRefetch(false);
                                }}
                            />

                            <Button
                                btnText="Add products"
                                btnClassname="!py-3 !whitespace-nowrap"
                                isLoading={false}
                                onClick={onAddProducts}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={editMapped}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                closeModal={() => {
                    setEditMapped(false);
                }}
            >
                <div className="bg-white py-7 px-6  shadow-cardShadow relative rounded-[8px]">
                    <div className="mb-5">
                        <h1 className="text-lg mb-2  font-rocGroteskMedium">Edit Mapped SKU?</h1>
                        <p className="text-slate-500 font-rocGroteskMedium text-base">
                            You’re about to edit the mapped SKU details. Ensure all necessary
                            changes are accurate, as this will affect future orders and inventory
                            tracking.
                        </p>
                    </div>
                    <div className="flex justify-end items-center ">
                        <div className="flex items-center !whitespace-nowrap space-x-2 ">
                            <Button
                                btnText={"No, cancel"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !w-fit "}
                                disabled={false}
                                onClick={() => setEditMapped(false)}
                            />
                            <Button
                                btnClassname={"!py-3 !w-fit"}
                                onClick={onUpdateSkuMapping}
                                isLoading={updatingSkuMapping}
                                disabled={updatingSkuMapping}
                                btnText={"Edit SKU"}
                                type={"button"}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={editSuccess}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[484px]"
                showCloseIcon={false}
                closeModal={() => {
                    dispatch(inventoryActions.resetUpdateSkuMappingSuccess());
                    setShowEditSuccess(false);
                    navigate("/dashboard/inventory?currTab=5");
                }}
            >
                <div className="bg-white !px-2 pt-6 pb-7  rounded-[12px]">
                    <div className="flex px-6 items-center justify-end">
                        <span
                            className="material-icons text-gm-45 text-[24px] cursor-pointer font-medium"
                            onClick={() => {
                                dispatch(inventoryActions.resetUpdateSkuMappingSuccess());
                                setShowEditSuccess(false);
                                navigate("/dashboard/inventory?currTab=5");
                            }}
                        >
                            close
                        </span>
                    </div>
                    <div className="mt-4 px-6">
                        <div className="text-center flex justify-center flex-col items-center space-y-5">
                            <div className="w-[48px] h-[48px] flex justify-center items-center bg-[#00DB8F] rounded-full">
                                <i className="ri-checkbox-circle-fill text-[29px] text-white "></i>
                            </div>
                            <div>
                                <h3 className="text-xl text-[#333333] font-rocGroteskMedium">
                                    Mapped SKU Updated
                                </h3>
                                <p className="text-slate-500 mt-1 text-sm font-rocGroteskMedium">
                                    The SKU has been successfully updated and is now reflected in
                                    your system. All future orders and inventory tracking will use
                                    this updated SKU, ensuring accuracy and consistency across your
                                    operations.
                                </p>
                            </div>
                        </div>
                        <div className="mt-5 flex items-center space-x-2">
                            <div className="w-full">
                                <Button
                                    btnText={"Close"}
                                    btnClassname="!py-3 cursor-pointer !bg-[#F4F5F7] !text-[14px] !text-gm-50 !border border-n-20 !font-rocGroteskMedium !px-4"
                                    onClick={() => {
                                        dispatch(inventoryActions.resetUpdateSkuMappingSuccess());
                                        setShowEditSuccess(false);
                                        navigate("/dashboard/inventory?currTab=5");
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </PageFrame>
    );
};

export default EditSku;
