import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { dashboardActions, inventoryActions } from "redux/Ldb/actions";
import { debounce, uniqBy } from "lodash";
import useInfiniteScroll from "hooks/useInfinteScroll";

const useGetInventoryTotalStockValue = (fetchData: boolean, scrollContent?: HTMLElement) => {
    const dispatch = useAppDispatch();
    const [totalInventoryStockValue, setTotalInventoryStockValue] = useState<{ [key: string]: any }>([]);
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [sortValues, setSortValues] = useState<any>({});
    const [startDateRange, setStartDateRange] = useState<string>("");
    const [endDateRange, setEndDateRange] = useState<string>("");
    const [warehouseId, setWarehouseId] = useState<string>("");
    const [inventoryId, setInventoryId] = useState<string>("");
    const [channel, setChannel] = useState<string>("");
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }| any>({
        currentPage: "1",
        noOfPages: "",
    });

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
    useInfiniteScroll(
        { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
        10,
        scrollContent
    );

    const {
        fetchingInventoryTotalStockValue,
        fetchedInventoryTotalStockValueSuccess,
        fetchedInventoryTotalStockValueFailure,
    } = useAppSelector((state) => state.inventory);

    const handleDebouncedChange = debounce((e) => {
        setDebouncedSearch(e?.target?.value);
    }, 500);

    const handleChannelChange = (value: string) => {
        setUpdatedPage(1);
        setTotalInventoryStockValue([]);
        dispatch(
            inventoryActions.fetchInventoryTotalStockValue(
                warehouseId,
                channel,
                10,
                1,
                debouncedSearch,
                startDateRange,
                endDateRange,
                sortValues,
            )
        );
        setChannel(value);
    };
    useEffect(() => {
        if (Boolean(fetchedInventoryTotalStockValueSuccess)) {
            setTotalInventoryStockValue((prev:any) =>
            updatedPage === 1
                ? uniqBy([...fetchedInventoryTotalStockValueSuccess?.stockInfo], "_id")
                : uniqBy([...prev, ...fetchedInventoryTotalStockValueSuccess?.stockInfo], "_id")
        );
           
            setPagination({
                currentPage: fetchedInventoryTotalStockValueSuccess.pagination?.current,
                noOfPages: fetchedInventoryTotalStockValueSuccess.pagination?.number_of_pages,
            });
       
        }
    }, [fetchedInventoryTotalStockValueSuccess, updatedPage]);

    useEffect(() => {
        if (fetchData) {
            dispatch(
                inventoryActions.fetchInventoryTotalStockValue(
                    warehouseId,
                    channel,
                    10,
                    updatedPage,
                    startDateRange,
                    endDateRange,
                    debouncedSearch,
                    sortValues,
                )
            );
            return () => {
                dispatch(inventoryActions.resetfetchInventoryTotalStockValue());
            };
        }
    }, [fetchData,
        warehouseId,
        channel,
        updatedPage,
        debouncedSearch,
        startDateRange,
        endDateRange,
        sortValues,
    ]);

    return {
        data: totalInventoryStockValue,
        isFetching: fetchingInventoryTotalStockValue,
        error: fetchedInventoryTotalStockValueFailure,
        handleDebouncedChange,
        setStartDateRange,
        setEndDateRange,
        pagination,
        setPagination,
        setSortValues,
        setWarehouseId,
        setInventoryId,
        setDebouncedSearch,
        setChannel,
        isLoadingMore,
        debouncedSearch,
        setUpdatedPage
    };
};

export default useGetInventoryTotalStockValue;
