import Button from "components/common/Button";
import Loader from "components/common/Loader";
import ModalContainer from "components/common/ModalContainer";
import useGetAssetAccounts from "hooks/integrationHooks/useGetAssetAccounts";
import useGetIncomeAccounts from "hooks/integrationHooks/useGetIncomeAccounts";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { integrationActions, vendorActions } from "redux/Ldb/actions";

interface Props {
    qbAPAccountRef?: {
        id: string;
        name: string;
    };
    qbExpenseAccountRef?: {
        id: string;
        name: string;
    };
    qbIncomeAccountRef?: {
        id: string;
        name: string;
    };
    qbAssetAccountRef?: {
        id: string;
        name: string;
    };
}
const IntegrationDetails = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { integrationName, integrationId } = useParams() || {};
    const [socialAccount, setSocialAccount] = useState<any>([]);
    const [expenseAccounts, setExpenseAccounts] = useState([]);
    const [payableAccounts, setPayableAccounts] = useState([]);
    const [combinedArray, setCombinedArray] = useState([]);
    const [formattedArray, setFormattedArray] = useState([]);
    const [selectedExpense, setSelectedExpense] = useState(null);
    const [totalSelected, setTotalSelected] = useState<number>(0);
    const [totalSelectedTwo, setTotalSelectedTwo] = useState<number>(0);
    const [selectedLiability, setSelectedLiability] = useState(null);
    const [syncedAccount, setSyncedAccount] = useState<boolean>(false);
    const [disconnected, setDisconnected] = useState<boolean>(false);
    const [selectedAccounts, setSelectedAccounts] = useState([]);
    const [showButton, setShowButton] = useState(false);

    const {
        fetchSocialAccount,
        fetchSocialAccountSuccess,
        setQuickbooksAccount,
        setQuickbooksAccountSuccess,
        fetchingQuickbooksExpenseAccounts,
        fetchingQuickbooksApAccounts,
        fetchedQuickbooksApAccountsSuccess,
        fetchedQuickbooksExpenseAccountsSuccess,
        updateSocialAccount,
        updateSocialAccountSuccess,
    } = useAppSelector((state) => state.integration);
    const { fetchedAllChannelsSuccess, manageConnectionSuccess, manageConnection } = useAppSelector(
        (state) => state.vendor
    );
    const { data: incomeAccounts } = useGetIncomeAccounts();
    const { data: assetAccounts } = useGetAssetAccounts();

    useEffect(() => {
        dispatch(vendorActions.getAllChannels());
    }, [dispatch]);

    const googleConnected = fetchedAllChannelsSuccess?.channel?.find(
        (item) => item?.channel?.toLowerCase() === "gmail"
    );
    const microsoftConnected = fetchedAllChannelsSuccess?.channel?.find(
        (item) => item?.channel?.toLowerCase() === "microsoft"
    );
    const quickBooksConnected = fetchedAllChannelsSuccess?.channel?.find(
        (item) => item?.channel?.toLowerCase() === "quickbooks"
    );
    const shopifyConnected = fetchedAllChannelsSuccess?.channel?.find(
        (item) => item?.channel?.toLowerCase() === "shopify"
    );
    const amazonConnected = fetchedAllChannelsSuccess?.channel?.find(
        (item) => item?.channel?.toLowerCase() === "amazon"
    );

    useEffect(() => {
        if (integrationId) {
            dispatch(integrationActions.fetchSocialAccount(integrationId));
        }
    }, [dispatch, integrationId]);

    const handleGmailDeactivate = () => {
        if (googleConnected?.active) {
            const body = {
                activate: false,
                socialId: googleConnected?._id,
            };
            dispatch(vendorActions.manageConnection(body));
        }
    };
    const handleShopifyDeactivate = () => {
        if (shopifyConnected?.active) {
            const body = {
                activate: false,
                socialId: shopifyConnected?._id,
            };
            dispatch(vendorActions.manageConnection(body));
        }
    };

    const handleMicrosoftDeactivate = () => {
        if (microsoftConnected?.active) {
            const body = {
                activate: false,
                socialId: microsoftConnected?._id,
            };
            dispatch(vendorActions.manageConnection(body));
        }
    };
    const handleQuickbooksDeactivate = () => {
        if (quickBooksConnected?.active) {
            const body = {
                activate: false,
                socialId: quickBooksConnected?._id,
            };
            dispatch(vendorActions.manageConnection(body));
        }
    };
    const handleAmazonDeactivate = () => {
        if (amazonConnected?.active) {
            const body = {
                activate: false,
                socialId: amazonConnected?._id,
            };
            dispatch(vendorActions.manageConnection(body));
        }
    };

    useEffect(() => {
        if (disconnected && integrationName.toLowerCase() === "google") {
            handleGmailDeactivate();
        }
    }, [disconnected, integrationName]);

    useEffect(() => {
        if (disconnected && integrationName.toLowerCase() === "amazon") {
            handleAmazonDeactivate();
        }
    }, [disconnected, integrationName]);

    useEffect(() => {
        if (disconnected && integrationName.toLowerCase() === "microsoft") {
            handleMicrosoftDeactivate();
        }
    }, [disconnected, integrationName]);

    useEffect(() => {
        if (disconnected && integrationName.toLowerCase() === "quickbooks") {
            handleQuickbooksDeactivate();
        }
    }, [disconnected, integrationName]);

    useEffect(() => {
        if (disconnected && integrationName.toLowerCase() === "shopify") {
            handleShopifyDeactivate();
        }
    }, [disconnected, integrationName]);

    useEffect(() => {
        if (Boolean(manageConnectionSuccess)) {
            setDisconnected(false);
            dispatch(vendorActions.resetManageConnectionSuccess());
            dispatch(vendorActions.getAllChannels());
            navigate("/dashboard/integrations");
        }
    }, [dispatch, manageConnectionSuccess]);

    useEffect(() => {
        if (Boolean(fetchSocialAccountSuccess)) {
            setSocialAccount(fetchSocialAccountSuccess?.account);
        }
    }, [dispatch, fetchSocialAccountSuccess]);

    const SocialData = [
        {
            name: "Quickbooks",
            img: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1713797491/Frame_2611386_ievdjs.svg",
        },
        {
            name: "Google",
            img: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1713957047/gmail_1_zya4ai.svg",
        },
        {
            name: "Shopify",
            img: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1713797582/shopify_w87tn3.svg",
        },
        {
            name: "Microsoft",
            img: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1713957047/gmail_1_zya4ai.svg",
        },
        {
            name: "Amazon",
            img: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1721661270/Gmail_icon__2020_2_1_b0o8gy.svg",
        },
    ];

    const isIntegrationNameCorrect = SocialData?.find(
        (item) => item?.name?.toLowerCase() === integrationName
    );

    useEffect(() => {
        if (quickBooksConnected?.active) {
            dispatch(integrationActions.getQuickbooksExpenseList());
            dispatch(integrationActions.getQuickbooksApList());
        }
    }, [dispatch, quickBooksConnected?.active]);

    useEffect(() => {
        const mergedArray = [
            { "Expense Account(s)": expenseAccounts || [] },
            { "Payable Account(s)": payableAccounts || [] },
            { "Income Account(s)": incomeAccounts || [] },
            { "Asset Account(s)": assetAccounts || [] },
        ];

        setFormattedArray(mergedArray);
    }, [expenseAccounts, payableAccounts, incomeAccounts, assetAccounts]);

    useEffect(() => {
        if (Boolean(fetchedQuickbooksApAccountsSuccess)) {
            setPayableAccounts(fetchedQuickbooksApAccountsSuccess?.accounts);
        }
    }, [fetchedQuickbooksApAccountsSuccess]);
    useEffect(() => {
        if (Boolean(fetchedQuickbooksExpenseAccountsSuccess)) {
            setExpenseAccounts(fetchedQuickbooksExpenseAccountsSuccess?.accounts);
        }
    }, [fetchedQuickbooksExpenseAccountsSuccess]);

    useEffect(() => {
        if (syncedAccount) {
            const timeoutId = setTimeout(() => {
                setSyncedAccount(false);
            }, 2000);

            return () => clearTimeout(timeoutId);
        }
    }, [syncedAccount]);
    useEffect(() => {
        if (Boolean(setQuickbooksAccountSuccess)) {
            dispatch(vendorActions.getAllChannels());
            dispatch(integrationActions.resetSetUpQuickbooksSuccess());
            setSyncedAccount(true);
        }
    }, [setQuickbooksAccountSuccess]);

    useEffect(() => {
        const requiredKeys = [
            "Payable Account(s)",
            "Expense Account(s)",
            "Income Account(s)",
            "Asset Account(s)",
        ];

        const hasAll = requiredKeys.every((key) =>
            selectedAccounts.some((item) => Object.keys(item)[0] === key)
        );
        setShowButton(hasAll);
    }, [selectedAccounts, socialAccount]);

    const handleUpdate = () => {
        const qbAPAccountRef = selectedAccounts?.find(
            (item) => Object.keys(item)[0] === "Payable Account(s)"
        );
        const qbExpenseAccountRef = selectedAccounts?.find(
            (item) => Object.keys(item)[0] === "Expense Account(s)"
        );
        const qbIncomeAccountRef = selectedAccounts?.find(
            (item) => Object.keys(item)[0] === "Income Account(s)"
        );
        const qbAssetAccountRef = selectedAccounts?.find(
            (item) => Object.keys(item)[0] === "Asset Account(s)"
        );

        const body: any = {};

        if (qbAPAccountRef?.["Payable Account(s)"]?.Id) {
            body.qbAPAccountRef = {
                id: qbAPAccountRef["Payable Account(s)"].Id,
                name: qbAPAccountRef["Payable Account(s)"].Name,
            };
        }

        if (qbExpenseAccountRef?.["Expense Account(s)"]?.Id) {
            body.qbExpenseAccountRef = {
                id: qbExpenseAccountRef["Expense Account(s)"].Id,
                name: qbExpenseAccountRef["Expense Account(s)"].Name,
            };
        }

        if (qbIncomeAccountRef?.["Income Account(s)"]?.Id) {
            body.qbIncomeAccountRef = {
                id: qbIncomeAccountRef["Income Account(s)"].Id,
                name: qbIncomeAccountRef["Income Account(s)"].Name,
            };
        }

        if (qbAssetAccountRef?.["Asset Account(s)"]?.Id) {
            body.qbAssetAccountRef = {
                id: qbAssetAccountRef["Asset Account(s)"].Id,
                name: qbAssetAccountRef["Asset Account(s)"].Name,
            };
        }

        if (body) {
            dispatch(integrationActions.updateSocialAccount(body, integrationId));
        }
    };

    useEffect(() => {
        if (Boolean(updateSocialAccountSuccess)) {
            setSelectedAccounts([]);
            dispatch(integrationActions.fetchSocialAccount(integrationId));
            if (quickBooksConnected?.active) {
                dispatch(integrationActions.getQuickbooksExpenseList());
                dispatch(integrationActions.getQuickbooksApList());
            }
            dispatch(integrationActions.resetUpdateSocialAccountSuccess());
        }
    }, [updateSocialAccountSuccess, integrationId, dispatch]);

    return (
        <div className="py-4 lg:border pb-20 border-slate-100 rounded-[8px]">
            <div className="px-6 max-lg:hidden block  pt-3 pb-5 border-b rounded border-slate-100">
                <div
                    className="flex items-center space-x-2 w-fit cursor-pointer"
                    onClick={() => navigate(-1)}
                >
                    <i className="ri-arrow-left-line text-[20px]"></i>
                    <p className="text-sm font-rocGroteskMedium underline">Back</p>
                </div>
            </div>
            {isIntegrationNameCorrect ? (
                <>
                    <div className="px-6 border-b rounded  py-3">
                        <div className="xs:flex-row flex-col flex justify-between">
                            <div>
                                {SocialData?.map((data, idx) => (
                                    <div key={idx}>
                                        {data.name.toLowerCase() === integrationName && (
                                            <div key={idx} className="flex items-center space-x-2">
                                                <img src={data.img} alt={data.name} />
                                                <p className="text-base text-[#0F172A]  font-rocGroteskMedium">
                                                    {data?.name}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                ))}

                                <div
                                    className={` w-fit mt-3 bg-g-25
                    py-[2px] px-[8px] rounded`}
                                >
                                    <p className={`text-xs text-g-60 font-rocGroteskMedium`}>
                                        Connected account
                                    </p>
                                </div>
                            </div>
                            <div className="mt-3 sm:block hidden">
                                <Button
                                    btnText={`Disconnect ${
                                        integrationName.charAt(0).toUpperCase() +
                                        integrationName.slice(1)
                                    }`}
                                    type={"button"}
                                    isLoading={manageConnection}
                                    icon={<i className="ri-indeterminate-circle-line"></i>}
                                    btnClassname="!py-2  !bg-btn-light-red_3 !border !border-r-55 !text-[14px] !text-r-55 !px-4"
                                    onClick={() => setDisconnected(true)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="lg:px-6 mt-5 flex lg:flex-row flex-col lg:space-x-10">
                        {integrationName.toLowerCase() === "quickbooks" && (
                            <>
                                <div className="lg:bg-white lg:w-[60%] lg:shadow-boxShadow-9 p-3 lg:p-6 rounded-[20px]">
                                    {socialAccount?.status !== "completed" && (
                                        <p className="text-xs text-slate-500 font-rocGroteskMedium">
                                            {socialAccount?.syncStatus === "inprogress"
                                                ? "Your account sync is in progress"
                                                : socialAccount?.syncStatus === "failed"
                                                ? "Your account sync failed"
                                                : socialAccount?.syncStatus === "pending" &&
                                                  "Your account has not been synced"}
                                        </p>
                                    )}
                                    <div className="mt-6">
                                        <div className="flex items-center justify-between mb-2">
                                            <p className="text-[12px] uppercase tracking-[2px] leading-[17.86px] text-slate-500 font-rocGroteskMedium">
                                                All Accounts
                                            </p>
                                            {formattedArray?.length > 0 && (
                                                <div>
                                                    <Button
                                                        onClick={handleUpdate}
                                                        btnText={`Sync selected accounts`}
                                                        type={"button"}
                                                        isLoading={updateSocialAccount}
                                                        disabled={
                                                            socialAccount?.syncStatus ===
                                                            "inprogress"
                                                                ? true
                                                                : socialAccount?.syncStatus ===
                                                                  "completed"
                                                                ? false
                                                                : !showButton
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        {fetchingQuickbooksApAccounts &&
                                        fetchSocialAccount &&
                                        fetchingQuickbooksExpenseAccounts ? (
                                            <div className="my-[10%]">
                                                <Loader />
                                            </div>
                                        ) : (
                                            <div className="bg-white px-2 rounded-[8px] min-h-fit lg:max-h-[55vh]  lg:overflow-y-scroll border border-slate-100">
                                                {formattedArray?.length > 0 ? (
                                                    <>
                                                        {formattedArray?.map((acc, idx) => {
                                                            const title = Object?.keys(acc);
                                                            const accountArray = acc[title[0]];

                                                            const getAccountRefKey = (title) => {
                                                                switch (title[0]) {
                                                                    case "Expense Account(s)":
                                                                        return "qbExpenseAccountRef";
                                                                    case "Payable Account(s)":
                                                                        return "qbAPAccountRef";
                                                                    case "Income Account(s)":
                                                                        return "qbIncomeAccountRef";
                                                                    case "Asset Account(s)":
                                                                        return "qbAssetAccountRef";
                                                                    default:
                                                                        return null;
                                                                }
                                                            };

                                                            const accountRefKey =
                                                                getAccountRefKey(title);
                                                            return (
                                                                <div key={idx} className="mt-4">
                                                                    <span className="text-xs text-slate-500 block font-rocGroteskMedium text-left mb-4 uppercase">
                                                                        {title[0]}
                                                                    </span>
                                                                    {accountArray?.map(
                                                                        (account, id) => {
                                                                            const selectedAccount =
                                                                                selectedAccounts?.find(
                                                                                    (acctItem) => {
                                                                                        return (
                                                                                            acctItem?.[
                                                                                                title[0]
                                                                                            ]
                                                                                                ?.Id ===
                                                                                            account.Id
                                                                                        );
                                                                                    }
                                                                                );

                                                                            const keysArray =
                                                                                selectedAccounts?.map(
                                                                                    (item) =>
                                                                                        Object.keys(
                                                                                            item
                                                                                        )[0]
                                                                                );

                                                                            const isSynced =
                                                                                socialAccount?.[
                                                                                    accountRefKey
                                                                                ]?.id ===
                                                                                    account?.Id &&
                                                                                !keysArray.includes(
                                                                                    title[0]
                                                                                );

                                                                            return (
                                                                                <div
                                                                                    key={id}
                                                                                    className="mb-3 p-3 border-b flex justify-between"
                                                                                >
                                                                                    <div className="flex space-x-3  ">
                                                                                        <div>
                                                                                            <div className="h-[38px] w-[38px] rounded-[200px] bg-slate-50 flex justify-center items-center">
                                                                                                <i className="ri-money-dollar-circle-fill text-slate-500 text-center"></i>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="text-sm font-rocGroteskMedium">
                                                                                            <h6 className=" text-[#0F172A]">
                                                                                                {
                                                                                                    account?.Name
                                                                                                }
                                                                                            </h6>
                                                                                            <p className="text-[#64748B] text-[12px]">
                                                                                                Account
                                                                                                ID:{" "}
                                                                                                {
                                                                                                    account?.Id
                                                                                                }
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>

                                                                                    {!isSynced ? (
                                                                                        <div
                                                                                            className={`border rounded px-[16px] cursor-pointer py-[8px] flex space-x-3 items-center border-slate-200 group hover:bg-light-green hover:border-g-55 hover:text-g-55 ${
                                                                                                selectedAccount &&
                                                                                                "bg-light-green border-g-55 text-g-55"
                                                                                            }`}
                                                                                            onClick={() => {
                                                                                                const selectedAccountIndex =
                                                                                                    [
                                                                                                        ...selectedAccounts,
                                                                                                    ].findIndex(
                                                                                                        (
                                                                                                            item
                                                                                                        ) => {
                                                                                                            return (
                                                                                                                item?.[
                                                                                                                    title[0]
                                                                                                                ]
                                                                                                                    ?.Id ===
                                                                                                                account.Id
                                                                                                            );
                                                                                                        }
                                                                                                    );

                                                                                                if (
                                                                                                    selectedAccountIndex !==
                                                                                                    -1
                                                                                                ) {
                                                                                                    const selectedAccountsCopy =
                                                                                                        [
                                                                                                            ...selectedAccounts,
                                                                                                        ];
                                                                                                    selectedAccountsCopy.splice(
                                                                                                        selectedAccountIndex,
                                                                                                        1
                                                                                                    );
                                                                                                    setSelectedAccounts(
                                                                                                        selectedAccountsCopy
                                                                                                    );
                                                                                                } else {
                                                                                                    const selectedAccountsCopy =
                                                                                                        [
                                                                                                            ...selectedAccounts,
                                                                                                        ];
                                                                                                    const itemCategoryIndex =
                                                                                                        selectedAccountsCopy.findIndex(
                                                                                                            (
                                                                                                                item
                                                                                                            ) => {
                                                                                                                return (
                                                                                                                    Object.keys(
                                                                                                                        item
                                                                                                                    )[0] ===
                                                                                                                    title[0]
                                                                                                                );
                                                                                                            }
                                                                                                        );

                                                                                                    if (
                                                                                                        itemCategoryIndex ===
                                                                                                        -1
                                                                                                    ) {
                                                                                                        // selectedAccountsCopy[itemCategoryIndex] = {
                                                                                                        //     [accountTitle[0]]: account
                                                                                                        // }
                                                                                                        setSelectedAccounts(
                                                                                                            (
                                                                                                                prev
                                                                                                            ) => {
                                                                                                                return [
                                                                                                                    ...prev,
                                                                                                                    {
                                                                                                                        [title[0]]:
                                                                                                                            account,
                                                                                                                    },
                                                                                                                ];
                                                                                                            }
                                                                                                        );
                                                                                                    }
                                                                                                }

                                                                                                // if (!selectedLiability) {
                                                                                                //     setSelectedLiability(
                                                                                                //         account
                                                                                                //     );
                                                                                                //     setTotalSelectedTwo(1);
                                                                                                // } else if (
                                                                                                //     selectedLiability?.Id ===
                                                                                                //     account.Id
                                                                                                // ) {
                                                                                                //     setTotalSelectedTwo(0);
                                                                                                //     setSelectedLiability(
                                                                                                //         null
                                                                                                //     );
                                                                                                // }
                                                                                            }}
                                                                                        >
                                                                                            <div className="h-fit">
                                                                                                <div
                                                                                                    className={`h-4 w-4 rounded-full border-[#A2ABAB] border group-hover:hidden ${
                                                                                                        selectedAccount &&
                                                                                                        "hidden"
                                                                                                    }`}
                                                                                                ></div>
                                                                                                <i
                                                                                                    className={`ri-check-fill text-g-55 hidden group-hover:block ${
                                                                                                        selectedAccount &&
                                                                                                        "!block"
                                                                                                    }`}
                                                                                                ></i>
                                                                                            </div>
                                                                                            <p className="text-[13px] font-rocGroteskMedium leading-[24px]">
                                                                                                Select
                                                                                            </p>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div className="border rounded   px-[16px] bg-light-green py-[8px] items-center  border-g-60  cursor-pointer">
                                                                                            <div className="flex space-x-3 items-center">
                                                                                                <div className="h-fit">
                                                                                                    <i className="ri-checkbox-circle-fill text-g-60"></i>
                                                                                                </div>
                                                                                                <p className="text-[13px] text-g-60 font-rocGroteskMedium leading-[24px]">
                                                                                                    Synced
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}

                                                                                    {/* {account?.Classification ===
                                                                        "Expense" &&
                                                                        selectedExpense?.Id !==
                                                                            account?.Id && (
                                                                            <div
                                                                                className={`border rounded px-[16px] cursor-pointer py-[8px] flex space-x-3 items-center border-slate-200 group hover:bg-light-green hover:border-g-55 hover:text-g-55
                                                     
                                                    `}
                                                                                onClick={() => {
                                                                                    if (
                                                                                        !selectedExpense
                                                                                    ) {
                                                                                        setSelectedExpense(
                                                                                            account
                                                                                        );
                                                                                        setTotalSelected(
                                                                                            1
                                                                                        );
                                                                                    } else if (
                                                                                        selectedExpense?.Id ===
                                                                                        account.Id
                                                                                    ) {
                                                                                        setTotalSelected(
                                                                                            0
                                                                                        );
                                                                                        setSelectedExpense(
                                                                                            null
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <div className="h-fit">
                                                                                    <div className="h-4 w-4 rounded-full border-[#A2ABAB] border group-hover:hidden"></div>
                                                                                    <i className="ri-check-fill text-g-55 hidden group-hover:block"></i>
                                                                                </div>
                                                                                <p className="text-[13px] font-rocGroteskMedium leading-[24px]">
                                                                                    Select
                                                                                </p>
                                                                            </div>
                                                                        )}
                                                                    {account?.Classification ===
                                                                        "Liability" &&
                                                                        selectedLiability?.Id !==
                                                                            account?.Id && (
                                                                            <div
                                                                                className={`border rounded px-[16px] cursor-pointer py-[8px] flex space-x-3 items-center border-slate-200 group hover:bg-light-green hover:border-g-55 hover:text-g-55
                                                    `}
                                                                                onClick={() => {
                                                                                    if (
                                                                                        !selectedLiability
                                                                                    ) {
                                                                                        setSelectedLiability(
                                                                                            account
                                                                                        );
                                                                                        setTotalSelectedTwo(
                                                                                            1
                                                                                        );
                                                                                    } else if (
                                                                                        selectedLiability?.Id ===
                                                                                        account.Id
                                                                                    ) {
                                                                                        setTotalSelectedTwo(
                                                                                            0
                                                                                        );
                                                                                        setSelectedLiability(
                                                                                            null
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <div className="h-fit">
                                                                                    <div className="h-4 w-4 rounded-full border-[#A2ABAB] border group-hover:hidden"></div>
                                                                                    <i className="ri-check-fill text-g-55 hidden group-hover:block"></i>
                                                                                </div>
                                                                                <p className="text-[13px] font-rocGroteskMedium leading-[24px]">
                                                                                    Select
                                                                                </p>
                                                                            </div>
                                                                        )}

                                                                    {(selectedExpense?.Id ===
                                                                        account?.Id ||
                                                                        selectedLiability?.Id ===
                                                                            account?.Id) && (
                                                                        <div
                                                                            className="border rounded px-[16px] cursor-pointer py-[8px] flex space-x-3 items-center border-g-55 text-g-55"
                                                                            onClick={() => {
                                                                                if (
                                                                                    selectedExpense?.Id ===
                                                                                    account.Id
                                                                                ) {
                                                                                    setSelectedExpense(
                                                                                        null
                                                                                    );
                                                                                    setTotalSelected(
                                                                                        totalSelected -
                                                                                            1
                                                                                    );
                                                                                }
                                                                                if (
                                                                                    selectedLiability?.Id ===
                                                                                    account.Id
                                                                                ) {
                                                                                    setSelectedLiability(
                                                                                        null
                                                                                    );
                                                                                    setTotalSelected(
                                                                                        totalSelected -
                                                                                            1
                                                                                    );
                                                                                }
                                                                            }}
                                                                        >
                                                                            <i className="ri-checkbox-circle-fill text-g-55"></i>
                                                                            <p className="text-[13px] font-rocGroteskMedium leading-[24px]">
                                                                                Selected
                                                                            </p>
                                                                        </div>
                                                                    )} */}
                                                                                </div>
                                                                            );
                                                                        }
                                                                    )}
                                                                </div>
                                                            );
                                                        })}
                                                    </>
                                                ) : (
                                                    <div className="items-center flex  h-[100%]  justify-center my-[10%]">
                                                        <div className="space-y-2 flex flex-col items-center justify-center  text-center">
                                                            <div className="h-[38px] w-[38px] rounded-[200px] bg-slate-50 flex justify-center items-center">
                                                                <i className="ri-money-dollar-circle-fill text-slate-500 text-center"></i>
                                                            </div>

                                                            <div>
                                                                <p className="text-slate-500  font-rocGroteskMedium text-[16px]">
                                                                    No other account
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="lg:bg-white lg:w-[40%] h-fit lg:shadow-boxShadow-9 p-6 rounded-[20px]">
                            <div>
                                <p className="text-xl font-rocGroteskMedium text-gm-50 mb-1">
                                    Information Synth is getting
                                </p>
                                <p className="text-[#6B778C] font-rocGroteskMedium text-sm">
                                    Here are the types of information that Synth is getting from
                                    your accounts
                                </p>
                            </div>
                            <div className="mt-7">
                                <div className="py-[16px] border-b border-[#EBECF0] flex justify-between">
                                    <div className="flex space-x-3  ">
                                        <div>
                                            <div className="h-[38px] w-[38px] rounded-[200px] bg-slate-50 flex justify-center items-center">
                                                <i className="ri-money-dollar-circle-fill text-slate-500 text-[22px] text-center"></i>
                                            </div>
                                        </div>
                                        <div className="text-sm font-rocGroteskMedium">
                                            <h6 className=" text-[#0F172A]">Sales information </h6>
                                            <p className="text-[#64748B] text-[12px]">
                                                E.g., sales volume, sales value, sales agents, sales
                                                channels, and any other relevant sales information
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="py-[16px] border-b border-[#EBECF0] flex justify-between">
                                    <div className="flex space-x-3  ">
                                        <div>
                                            <div className="h-[38px] w-[38px] rounded-[200px] bg-slate-50 flex justify-center items-center">
                                                <i className="ri-shopping-basket-2-fill text-slate-500 text-[22px] text-center"></i>
                                            </div>
                                        </div>
                                        <div className="text-sm font-rocGroteskMedium">
                                            <h6 className=" text-[#0F172A]">
                                                Inventory information{" "}
                                            </h6>
                                            <p className="text-[#64748B] text-[12px]">
                                                E.g., inventory volume, inventory value, retail
                                                stores, inventory managers, stock-outs, over-stocked
                                                products, and any other relevant inventory
                                                information.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {(integrationName.toLowerCase() === "google" ||
                                    integrationName.toLowerCase() === "microsoft" ||
                                    integrationName.toLowerCase() === "quickbooks") && (
                                    <div className="py-[16px] border-b border-[#EBECF0] flex justify-between">
                                        <div className="flex space-x-3  ">
                                            <div>
                                                <div className="h-[38px] w-[38px] rounded-[200px] bg-slate-50 flex justify-center items-center">
                                                    <i className="ri-shopping-basket-2-fill text-slate-500 text-[22px] text-center"></i>
                                                </div>
                                            </div>
                                            <div className="text-sm font-rocGroteskMedium">
                                                <h6 className=" text-[#0F172A]">
                                                    Purchase orders (PO) information{" "}
                                                </h6>
                                                <p className="text-[#64748B] text-[12px]">
                                                    E.g., suppliers, PO count, PO value, PO status,
                                                    shipment tracking information, and any other
                                                    relevant PO information.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="items-center flex  h-[100%]  justify-center my-[20%]">
                    <div className="space-y-2  text-center">
                        <i className="ri-file-list-3-line text-gm-50 text-[24px]"></i>

                        <p className="text-slate-500 font-rocGroteskMedium text-[12px]">
                            {integrationName} integration is not found
                        </p>
                    </div>
                </div>
            )}
            <div className="mt-3 sm:hidden block fixed z-[20] left-0 right-0 px-6 bottom-0 bg-white py-3">
                <Button
                    btnText={`Disconnect ${
                        integrationName.charAt(0).toUpperCase() + integrationName.slice(1)
                    }`}
                    type={"button"}
                    isLoading={manageConnection}
                    icon={<i className="ri-indeterminate-circle-line"></i>}
                    btnClassname="!py-2  !bg-btn-light-red_3 !border !border-r-55 !text-[14px] !text-r-55 !px-4"
                    onClick={() => setDisconnected(true)}
                />
            </div>
        </div>
    );
};

export default IntegrationDetails;
