import React from "react";
import { Toaster } from "react-hot-toast";
import { useRoutes } from "react-router-dom";
import routes from "./routes";
import config from "config/config";
import * as Ably from "ably";
import { AblyProvider } from "ably/react";
import { NavProvider } from "components/common/Navbar/NavContext";

const client = new Ably.Realtime({ key: config.ABLY_API_KEY });

function App() {
    const allRoutes = useRoutes(routes);

    return (
        <>
            <AblyProvider client={client}>
                <Toaster position="top-right" />
                <NavProvider>
                    <div className="App">{allRoutes}</div>
                </NavProvider>
            </AblyProvider>
        </>
    );
}

export default App;
