import { useEffect, useState } from "react";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { useParams } from "react-router-dom";

const useGetSingleMappedSku = () => {
    const dispatch = useAppDispatch();
    const { productId } = useParams();

    const [singleMappedSku, setSingleMappedSku] = useState<{ [key: string]: any }>({});

    const {
        fetchingSingleMappedSku,
        fetchedSingleMappedSkuSuccess,
        fetchedSingleMappedSkuFailure,
    } = useAppSelector((state) => state.inventory);

    useEffect(() => {
        dispatch(inventoryActions.fetchSingleMappedSku(productId));

        return () => {
            dispatch(inventoryActions.resetFetchMappedSkuListSuccess());
        };
    }, [dispatch, productId]);

    useEffect(() => {
        if (Boolean(fetchedSingleMappedSkuSuccess)) {
            console.log("fetchedSingleMappedSkuSuccess", fetchedSingleMappedSkuSuccess);
            setSingleMappedSku(fetchedSingleMappedSkuSuccess?.masterSku);
        }
    }, [fetchedSingleMappedSkuSuccess]);

    return {
        isFetching: fetchingSingleMappedSku,
        error: fetchedSingleMappedSkuFailure,
        data: singleMappedSku,
        setSingleMappedSku,
    };
};

export default useGetSingleMappedSku;
