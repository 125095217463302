import { useEffect, useState } from "react";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import useInfiniteScroll from "../useInfinteScroll";
import { debounce, uniqBy } from "lodash";

const useGetMappedSkusList = (warehouseId?: string) => {
    const dispatch = useAppDispatch();

    const [search, setSearch] = useState("");
    const [debounceSearch, setDebounceSearch] = useState("");
    const [mappedSkus, setMappedSkus] = useState<{ [key: string]: any }[]>([]);
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });
    const { fetchingMappeedSkuList, fetchedMappedSkuListSuccess, fetchedMappedSkuListFailure } =
        useAppSelector((state) => state.inventory);

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            10
        );

    const handleSearchChange = (value: string) => {
        setSearch(value);
    };

    const handleDebounceChange = debounce((e) => {
        setDebounceSearch(e.target.value);
    }, 800);

    useEffect(() => {
        dispatch(inventoryActions.fetchMappedSkuList(updatedPage, debounceSearch, warehouseId));

        return () => {
            dispatch(inventoryActions.resetFetchMappedSkuListSuccess());
        };
    }, [dispatch, updatedPage, debounceSearch, warehouseId]);

    useEffect(() => {
        if (Boolean(fetchedMappedSkuListSuccess)) {
            console.log("fetchedMappedSkuListSuccess", fetchedMappedSkuListSuccess);
            setMappedSkus((prev) =>
                updatedPage === 1
                    ? uniqBy([...fetchedMappedSkuListSuccess?.inventories], "_id")
                    : uniqBy([...prev, ...fetchedMappedSkuListSuccess?.inventories], "_id")
            );
            setPagination({
                currentPage: fetchedMappedSkuListSuccess.pagination?.current,
                noOfPages: fetchedMappedSkuListSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
        }
    }, [fetchedMappedSkuListSuccess, updatedPage]);

    return {
        isFetching: fetchingMappeedSkuList,
        error: fetchedMappedSkuListFailure,
        data: mappedSkus,
        search,
        debounceSearch,
        handleSearchChange,
        handleDebounceChange,
        setMappedSkus,
        pagination,
        setPagination,
        isLoadingMore,
        setUpdatedPage,
        updatedPage,
    };
};

export default useGetMappedSkusList;
