import CopilotChat from "components/common/CopilotChat";
import PageFrame from "components/layout/PageFrame";
import config from "config/config";
import { sortBy, uniqBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { chatActions } from "redux/Ldb/actions";
import { io } from "socket.io-client";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks/storeHooks";

// const socket = io(`${config.API_URL}/messaging`);

const GenerateWithAI = () => {
    const [chats, setChats] = useState<{ [key: string]: any }[]>([]);
    const [message, setMessage] = useState("");
    const [messageLoading, setMessageLoading] = useState(false);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [param] = useSearchParams();
    const conversationId = param.get("conversationId");

    const { state } = useLocation();
    // const { doc, docName } = state;
    const doc = state?.doc;
    const docName = state?.docName;
    const homePageChats = state?.chatsArr;

    const sortedChat = sortBy(
        uniqBy(
            chats?.filter((chat) => chat?.conversationId === conversationId),
            "_id"
        ),
        [
            function (o) {
                return o.createdAt;
            },
        ]
    );

    const handleChatChange = (value: string) => {
        setMessage(value);
    };

    const {
        fetchingConversation,
        fetchConversationSuccess,
        fetchConversationFailure,
        sendingAiMessage,
        sendAiMessageSuccess,
    } = useAppSelector((state) => state.chat);

    const handleSendMessage = (message: string) => {
        if (Boolean(doc)) {
            if (
                sortedChat?.length === 0 ||
                sortedChat?.[sortedChat?.length - 1]?.conversationStage?.stage === 1
            ) {
                return dispatch(
                    chatActions.sendAiMessage(
                        {
                            conversationId,
                            message,
                            media: [
                                {
                                    file_type: "document",
                                    file: doc,
                                },
                            ],
                        },
                        setChats
                    )
                );
            } else {
                return dispatch(
                    chatActions.sendAiMessage(
                        {
                            conversationId,
                            message,
                        },
                        setChats
                    )
                );
            }
        }
        return dispatch(
            chatActions.sendAiMessage(
                {
                    conversationId,
                    message,
                },
                setChats
            )
        );
    };

    // useEffect(() => {
    //     socket.on("new_ai_message", (data) => {
    //         setChats((prev) => {
    //             if (prev?.[prev.length - 1]?.loading) {
    //                 return [...prev.slice(0, -1), data];
    //             }

    //             return [...prev, data];
    //         });
    //     });

    //     // Remove event listener on component unmount
    //     return () => {
    //         socket.off("new_ai_message");
    //     };
    // }, []);

    useEffect(() => {
        if (Boolean(conversationId)) {
            dispatch(chatActions.getSingleConversation(conversationId as string));
        }
    }, [conversationId, dispatch]);

    useEffect(() => {
        if (Boolean(fetchConversationSuccess)) {
            if (homePageChats) {
                setChats(homePageChats);
            } else {
                setChats(fetchConversationSuccess?.data);
            }
        }

        if (Boolean(fetchConversationFailure)) {
            navigate("/dashboard/rfqs/create");
        }
    }, [fetchConversationSuccess, fetchConversationFailure, navigate, homePageChats]);

    useEffect(() => {
        if (Boolean(sendAiMessageSuccess)) {
            setChats((prev) => [...prev, sendAiMessageSuccess?.chat]);

            setMessage("");
            dispatch(chatActions.resetSendAiMessageSuccess());
        }
    }, [sendAiMessageSuccess, dispatch]);

    return (
        <PageFrame containerClassName={"!h-[calc(100vh-108px)]"} isLoading={fetchingConversation}>
            <CopilotChat
                chats={sortedChat}
                send={handleSendMessage}
                handleChatChange={handleChatChange}
                doc={doc}
                docName={docName}
                message={message}
                conversationId={conversationId as string}
                setChats={setChats}
            />
        </PageFrame>
    );
};

export default GenerateWithAI;
