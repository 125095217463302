import { useEffect, useState } from "react";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import useInfiniteScroll from "../useInfinteScroll";
import { firebaseService } from "services/firebaseService";
import { uniqBy } from "lodash";

const useGetEvents = (inventoryId:string, productName: string, eventCategory: string, eventImpact: string, ) => {
    console.log("productName", productName)
    console.log("inventoryId", inventoryId)
    const dispatch = useAppDispatch();
    const { fetchingEvents, fetchedEventsSuccess, fetchedEventsFailure } = useAppSelector(
        (state) => state.inventory
    );

    const [eventList, setEventList] = useState<{ [key: string]: any }[]>([]);
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            10
        );

    useEffect(() => {
        dispatch(
            inventoryActions.fetchEvents(inventoryId, productName, 15, updatedPage, eventCategory, eventImpact)
        );

        return () => {
            dispatch(inventoryActions.resetFetchEvents());
        };
    }, [dispatch, updatedPage, productName, eventCategory, eventImpact, inventoryId]);

    useEffect(() => {
        if (Boolean(fetchedEventsSuccess)) {
            const processData = async () => {
                try {
                    const newEvents = await Promise.all(
                        [...fetchedEventsSuccess?.events].map(async (event) => {
                            if (event.video) {
                                const processedVideo = await firebaseService.getMediaPath(
                                    event?.video?.split("appspot.com/")?.[1]
                                );
                                return {
                                    ...event,
                                    video: processedVideo,
                                };
                            } else {
                                return event;
                            }
                        })
                    );

                    setEventList((prev) =>
                        updatedPage === 1
                            ? uniqBy([...newEvents], "_id")
                            : uniqBy([...prev, ...newEvents], "_id")
                    );
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };
            processData();
            setPagination({
                currentPage: fetchedEventsSuccess.pagination?.current,
                noOfPages: fetchedEventsSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
        }
    }, [fetchedEventsSuccess, updatedPage]);

    return {
        isFetching: fetchingEvents,
        error: fetchedEventsFailure,
        data: eventList,
        setEventList,
        pagination,
        setPagination,
        isLoadingMore,
        setUpdatedPage,
    };
};

export default useGetEvents;
